<template>
  <footer
    class="footer"
    :class="{
      [`footer-${type}`]: type,
      [`footer-${size}`]: size
    }"
    :data-background-color="backgroundColor"
  >
    <template>
      <div class="container">
        <nav>
          <ul>
            <li>
              <a href="/">
                Home
              </a>
            </li>
            <li>
              <a href="/gallery">
                Gallery
              </a>
            </li>
            <li>
              <a href="/about">
                About Us
              </a>
            </li>
            <li>
              <a href="/consult">
                Request a Free Quote
              </a>
            </li>
          </ul>
        </nav>
        <div class="copyright">
          &copy; {{ year }} Lawn Escape Inc. All rights reserved.
        </div>
      </div>
    </template>
  </footer>
</template>
<script>
export default {
	props: {
		backgroundColor: String,
		type: String,
		size: String,
	},
	data() {
		return {
			year: new Date().getFullYear(),
		};
	},
};
</script>
<style></style>
