<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter" parallax-active="true" :style="headerStyle" >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              Gallery
            </h1>
            <h4>
                View our past work
            </h4>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container" id="home">
            <h4>
                <ul class="gallery" v-if="images">
					<li v-for="(image, index) in images" :key="index" style="height:200px" @click="selectImage(image)" class="hover">
						<img :src="`.${image.pathLong}`" alt="">
					</li>
				</ul>
            </h4>
        </div>
      </div>
    </div>

	<!-- The Modal -->
	<div id="modal" class="modal" v-if="currImage">

		<!-- The Close Button -->
		<span class="close" @click="closeImage()">&times;</span>

		<!-- Left Right Arrow -->
		<i class="arrow right" @click="transition('right')"></i>
		<i class="arrow left" @click="transition('left')"></i>

		<!-- Modal Content (The Image) -->
		<img :src="`.${currImage.pathLong}`" class="modal-content">

		<!-- Modal Caption (Image Text) -->
		<div id="caption"></div>
	</div>
  </div>
</template>

<script>
import Mixins from '@/plugins/basicMixins';

export default {
	mixins: [Mixins.HeaderImage],
	bodyClass: 'gallery',

	data() {
		return {
			image: require('@/assets/img/landscapes/gallery.jpg'),
			loading: false,
			images: [],
			currImage: null,
		};
	},

	mounted() {
		this.importAll(require.context('../assets/img/landscapes/fin/', true, /\.(png|jpe?g|svg)$/));
	},

	methods: {
		importAll(r) {
			console.log(r);
			r.keys().forEach(key => (this.images.push({ pathLong: r(key), pathShort: key })));
		},
		selectImage(img) {
			this.currImage = img;
		},
		closeImage() {
			this.currImage = null;
		},
		transition(direction) {
			if (direction === 'left') {
				this.currImage = this.images[this.images.indexOf(this.currImage) - 1 === -1 ? this.images.length - 1 : this.images.indexOf(this.currImage) - 1];
			}
			else {
				this.currImage = this.images[this.images.indexOf(this.currImage) + 1 === this.images.length ? 0 : this.images.indexOf(this.currImage) + 1];
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.md-card-actions.text-center {
		display: flex;
		justify-content: center !important;
	}
	.contact-form {
		margin-top: 30px;
	}

	.md-has-textarea + .md-layout {
		margin-top: 15px;
	}

	.container {
		display: flex;
		justify-content: center;
	}

	* {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
	}

	ul.gallery {
		--numcolumns: 4;
		--gap: 0.25em;
		--size: calc(100vw / var(--numcolumns));
		display: grid;
		grid-template-columns: repeat(var(--numcolumns), 1fr);

		grid-template-rows: auto;
		gap: var(--gap);
		align-items: stretch;

		list-style: none;
	}

	ul.gallery > li {
		display: block;
		height: calc(var(--size) - var(--gap));
	}

	ul.gallery > li img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	/* The Modal (background) */
	.modal {
		display: block; /* Hidden by default */
		position: fixed; /* Stay in place */
		z-index: 9999; /* Sit on top */
		padding-top: 100px; /* Location of the box */
		left: 0;
		top: 0;
		width: 100%; /* Full width */
		height: 100%; /* Full height */
		background-color: rgb(0,0,0); /* Fallback color */
		background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
	}

	.modal img {
		z-index: 9999999;
	}

	/* Modal Content (Image) */
	.modal-content {
		margin: auto;
		display: block;
		width: 80%;
		max-width: 700px;
	}

	/* Caption of Modal Image (Image Text) - Same Width as the Image */
	#caption {
		margin: auto;
		display: block;
		width: 80%;
		max-width: 700px;
		text-align: center;
		color: #ccc;
		padding: 10px 0;
		height: 150px;
	}

	/* Add Animation - Zoom in the Modal */
	.modal-content, #caption {
		animation-name: zoom;
		animation-duration: 0.6s;
	}

	@keyframes zoom {
		from {transform:scale(0)}
		to {transform:scale(1)}
	}

	/* The Close Button */
	.close {
		position: absolute;
		top: 15px;
		right: 35px;
		color: #f1f1f1;
		font-size: 40px;
		font-weight: bold;
		transition: 0.3s;
	}

	.close:hover,
	.close:focus,
	.right:hover,
	.right:focus,
	.left:hover,
	.left:focus {
		color: #bbb;
		text-decoration: none;
		cursor: pointer;
	}

	/* 100% Image Width on Smaller Screens */
	@media only screen and (max-width: 700px){
		.modal-content {
			width: 100%;
		}
	}

	.hover {
		cursor: pointer;
	}

	.arrow {
		border: solid #f1f1f1;
		border-width: 0 0.5rem 0.5rem 0;
		display: inline-block;
		padding: 0.5rem;
		font-weight: bold;
		transition: 0.3s;
	}

	.right {
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		position: absolute;
		top: 50%;
		right: 2em;
	}

	.left {
		transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
		position: absolute;
		top: 50%;
		left: 2em;
	}
</style>
