<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter" parallax-active="true" :style="headerStyle" >
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item">
            <img src="@/assets/img/FullWhite.png" alt="logo" class="logo">
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container" id="home">
          <!-- Begin Desciptions -->
          <div class="features text-center">
            <div class="md-layout">
              <div class="md-layout-item md-size-33 md-small-size-100">
                <div class="info">
                  <div class="icon icon-success">
                    <md-icon>attach_money</md-icon>
                  </div>
                  <h4 class="info-title">
                    Up Front Pricing
                  </h4>
                  <p>
                    99% of our projects finish within budget! Pricing exceeds budget when
                    changes are made mid project.
                  </p>
                </div>
              </div>
              <div class="md-layout-item md-size-33 md-small-size-100">
                <div class="info">
                  <div class="icon icon-danger">
                    <md-icon>remove_circle_outline</md-icon>
                  </div>
                  <h4 class="info-title">
                    No Minimums
                  </h4>
                  <p>
                    Most competitors charge minimums. We do not! You pay for labor and cost of materials.
                    That's all.
                  </p>
                </div>
              </div>
              <div class="md-layout-item md-size-33 md-small-size-100">
                <div class="info">
                  <div class="icon icon-info">
                    <md-icon>landscape</md-icon>
                  </div>
                  <h4 class="info-title">
                    Quick Turnaround
                  </h4>
                  <p>
                    We can usually begin work on your project within a week! Work will be completed professionally
                    and efficiently.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <br/>
          <!-- Begin Carousel -->
          <el-carousel :interval="4000" type="card" height="200px">
            <el-carousel-item class="w-100" v-for="item in carousel" :key="item.image">
              <img class="c-img" :src="item.image" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from '@/plugins/basicMixins';

export default {
	mixins: [Mixins.HeaderImage],
	bodyClass: 'landing-page',

	data() {
		return {
			image: require('@/assets/img/landscapes/main.jpg'),
			loading: false,
			recipe: null,
			carousel: [
				{image: require('@/assets/img/landscapes/main.jpg')},
				{image: require('@/assets/img/landscapes/fin/20201203-160001.jpg')},
				{image: require('@/assets/img/landscapes/backyard1.jpg')},
				{image: require('@/assets/img/landscapes/fin/consult.jpg')},
				{image: require('@/assets/img/landscapes/fin/20210809-162947.jpg')},
			],
		};
	},
	methods: {
	},
};
</script>

<style lang="scss" scoped>
  .title {
    font-size: 8em;
  }

  .md-card-actions.text-center {
    display: flex;
    justify-content: center !important;
  }
  .contact-form {
    margin-top: 30px;
  }

  .md-has-textarea + .md-layout {
    margin-top: 15px;
  }

  .container {
    display: flex;
    justify-content: center;
  }

  .c-img {
    object-fit: cover;
  }
</style>
