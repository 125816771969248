<template>
  <div>
    <div
      v-for="(item, index) in collapse"
      :key="item"
      :class="[
        'md-collapse',
        activeCollapse(index),
        { [getColorCollapse(colorCollapse)]: true }
      ]"
    >
      <div
        class="md-collapse-label"
        @click="toggle(index)"
      >
        <h5 class="md-collapse-title">
          {{ item }}
          <md-icon>{{ icon }}</md-icon>
        </h5>
      </div>

      <collapse-transition :duration="350">
        <div
          v-show="getActiveCollapse(index)"
          class="md-collapse-content"
        >
          <slot :name="getCollapseContent(index)" />
        </div>
      </collapse-transition>
    </div>
  </div>
</template>

<script>
import { CollapseTransition } from 'vue2-transitions';

export default {
	name: 'Collapse',
	components: {
		CollapseTransition,
	},
	props: {
		collapse: Array,
		icon: String,
		colorCollapse: String,
		activeTabs: Array,
	},
	data() {
		return {
			isActive: this.activeTabs,
		};
	},
	methods: {
		getActiveCollapse(index) {
			return this.isActive.includes(index);
		},
		activeCollapse(index) {
			return {
				'is-active': this.isActive.includes(index),
			};
		},
		toggle(index) {
			if (this.isActive.includes(index)) {
				const i = this.isActive.indexOf(index);
				if (i > -1) {
					this.isActive.splice(i, 1);
				}
			}
			else {
				this.isActive.push(index);
			}
		},
		getCollapseContent(index) {
			return 'md-collapse-pane-' + index + '';
		},
		getColorCollapse(colorCollapse) {
			return 'md-' + colorCollapse + '';
		},
	},
};
</script>

<style lang="scss" scoped>
  .text-center {
    display: flex;
  }
</style>
