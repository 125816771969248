const state = {
	type: null,
	message: null,
};

const actions = {
	success({ commit }, message) {
		commit('success', message);
	},
	error({ commit }, message) {
		commit('error', message);
	},
	info({ commit }, message) {
		commit('info', message);
	},
	warning({ commit }, message) {
		commit('warning', message);
	},
	clear({ commit }) {
		commit('clear');
	},
};

const mutations = {
	success(state, message) {
		state.type = 'alert-success';
		state.message = message;
		state.icon = 'check';
		state.title = 'SUCCESS';
	},
	error(state, message) {
		state.type = 'alert-danger';
		state.message = message;
		state.icon = 'info_outline';
		state.title = 'ERROR';
	},
	info(state, message) {
		state.type = 'alert-info';
		state.message = message;
		state.icon = 'info_outline';
		state.title = 'INFO';
	},
	warning(state, message) {
		state.type = 'alert-warning';
		state.message = message;
		state.icon = 'warning';
		state.title = 'WARNING';
	},
	clear(state) {
		state.type = null;
		state.message = null;
	},
};

export const alert = {
	namespaced: true,
	state,
	actions,
	mutations,
};