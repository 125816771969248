<template>
    <div :class="['alert', `alert-${data.type}`]">
        <div class="container">
          <div class="alert-icon">
            <md-icon v-if="data.type === 'success'">check</md-icon>
			<md-icon v-if="data.type === 'danger'">info_outline</md-icon>
          </div>

          {{data.message}}
        </div>
    </div>
</template>

<script>
export default {
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {

		};
	},
};
</script>


<style scoped>
    .alert {
        left: 0;
		margin: auto;
		position: fixed;
		right: 0;
		text-align: center;
		bottom: 2em;
		width: 80%;
		z-index: 1;
        z-index: 9999;
    }
</style>