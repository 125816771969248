<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter" parallax-active="true" :style="headerStyle" >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              Request a free quote
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container" id="home">
          <!-- Begin Contact -->
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100 ml-auto">
				<h2 class="mt-0">
					Send us a message
					</h2>
				<p class="description">
					Send us a short description of your project and we will reach back out to you as soon as possible.
					<br>
					<br>
				</p>
				<form ref="form" @submit.prevent="sendEmail">
					<md-field>
						<label>Your Name</label>
						<md-input
						v-model="name"
						type="text"
						name="name"
						/>
					</md-field>
					<md-field>
						<label>Email Adress</label>
						<md-input
						v-model="email"
						type="email"
						name="email"
						/>
					</md-field>
					<md-field>
						<label>Phone</label>
						<md-input
						v-model="phone"
						type="number"
						name="phone"
						/>
					</md-field>
					<md-field>
						<label>Your message</label>
						<md-textarea v-model="textarea" name="message"/>
					</md-field>
					<div class="submit text-center mt-3">
						<md-button class="md-primary md-round" @click="sendEmail()">
						Contact Us
						</md-button>
					</div>
				</form>
			</div>
            <div class="md-layout-item md-size-33 md-small-size-100 ml-auto">
              <info-areas
                class="pt-0"
                info-horizontal
                icon-color="primary"
                icon="phone"
              >
                <h4
                  slot="title"
                  class="info-title"
                >
                or shoot us a text
                </h4>
                <div
                  slot="content"
                  class="description"
                >
                  <p>
                    Roger <br>
                    +1 (702) 956-0033 <br>
                    Mon - Fri, 8:00 AM - 5:00 PM
                  </p>
                </div>
              </info-areas>
            </div>
          </div>
        </div>
      </div>
    </div>
	<Notification :data="notifyObj" v-if="notifyObj.show">
	</Notification>
  </div>
</template>

<script>
import Mixins from '@/plugins/basicMixins';
import { InfoAreas } from '@/components';
import emailjs from '@emailjs/browser';
import Notification from '@/components/Notification';

export default {
	mixins: [Mixins.HeaderImage],
	bodyClass: 'contact-us',

	data() {
		return {
			image: require('@/assets/img/landscapes/consult.jpg'),
			name: '',
			email: '',
			phone: '',
			textarea: '',
			notifyObj: {
				message: 'This is a test and should not be reached ',
				show: false,
				type: 'success',
			},
		};
	},
	components: {
		Notification,
		InfoAreas,
	},
	methods: {
		sendEmail() {
			if (!this.name) {
				this.notify('Please enter your name', 'danger');
				return;
			}

			if (!this.email) {
				this.notify('Please enter your email', 'danger');
				return;
			}

			if (!this.phone) {
				this.notify('Please enter your phone', 'danger');
				return;
			}

			if (!this.textarea) {
				this.notify('Please enter your message', 'danger');
				return;
			}

			emailjs.sendForm('service_lawnesc', 'template_nyk6ozb', this.$refs.form, '5RtH1pEzkKJq6Wqbz')
				.then(() => {
					this.$refs.form.reset();
					this.notify('success', 'Your message has been sent!');
				}, () => {
					this.$refs.form.reset();
					this.notify('There was an error sending your message, please try again or shoot us a text!', 'danger');
				});
		},
		notify(nessage, type) {
			this.notifyObj.message = nessage;
			this.notifyObj.type = type;

			this.notifyObj.show = true;
			setTimeout(() => {
				this.notifyObj.show = false;
			}, 4000);
		},
	},

};
</script>

<style lang="scss" scoped>
.mt-3 {
  margin-top: 1.5rem;
}
</style>