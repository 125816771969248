<template>
  <md-toolbar
    id="toolbar"
    md-elevation="0"
    class="md-fixed"
    :class="{
      [`md-${type}`]: !navbarTransparent,
      [extraNavClasses]: navbarTransparent,
      'md-transparent': navbarTransparent
    }"
    :color-on-scroll="colorOnScroll"
  >
    <div class="md-toolbar-row md-collapse-lateral">
      <div class="md-toolbar-section-start">
        <a href="/">
          <h3 class="md-title">
            {{ brand }}
          </h3>
        </a>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: toggledClass }"
          @click="toggleNavbarMobile()"
        >
          <span class="icon-bar" />
          <span class="icon-bar" />
          <span class="icon-bar" />
        </md-button>

        <div class="md-collapse">
          <div class="md-collapse-wrapper">
            <mobile-menu nav-mobile-section="false">
              <!-- Here you can add your items from the section-start of your toolbar -->
            </mobile-menu>
            <md-list>

              <li class="md-list-item">
                <a
                  href="/gallery"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <md-button
                      slot="title"
                      class="md-button md-button-link md-white md-simple"
                    >
                      <p>Gallery</p>
                    </md-button>
                  </div>
                </a>
              </li>

			<li class="md-list-item">
                <a
                  href="/about"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <md-button
                      slot="title"
                      class="md-button md-button-link md-white md-simple"
                    >
                      <p>About Us</p>
                    </md-button>
                  </div>
                </a>
			</li>

              <li class="md-list-item">
                <a
                  href="/consult"
                  class="md-list-item-router md-list-item-container md-button-clean"
                >
                  <div class="md-list-item-content ">
                    <md-button
                      class="md-success md-round"
                    >Request A Free Quote</md-button>
                  </div>
                </a>
              </li>
            </md-list>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
let resizeTimeout;
function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
	if (!resizeTimeout) {
		resizeTimeout = setTimeout(() => {
			resizeTimeout = null;
			actualResizeHandler();

      // The actualResizeHandler will execute at a rate of 15fps
		}, 66);
	}
}

import MobileMenu from '@/layout/MobileMenu';
export default {
	components: {
		MobileMenu,
	},
	props: {
		type: {
			type: String,
			default: 'white',
			validator(value) {
				return [
					'white',
					'default',
					'primary',
					'danger',
					'success',
					'warning',
					'info',
					'dark',
				].includes(value);
			},
		},
		colorOnScroll: {
			type: Number,
			default: 0,
		},
		navbarTransparent: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			extraNavClasses: '',
			toggledClass: false,
			brand: 'Lawn Escape',
		};
	},
	computed: {
		showDownload() {
			const excludedRoutes = ['index'];
			return excludedRoutes.every(r => r !== this.$route.name);
		},
	},
	mounted() {
		document.addEventListener('scroll', this.scrollListener);
	},
	beforeDestroy() {
		document.removeEventListener('scroll', this.scrollListener);
	},
	methods: {
		bodyClick() {
			const bodyClick = document.getElementById('bodyClick');

			if (bodyClick === null) {
				const body = document.querySelector('body');
				const elem = document.createElement('div');
				elem.setAttribute('id', 'bodyClick');
				body.appendChild(elem);

				const bodyClick = document.getElementById('bodyClick');
				bodyClick.addEventListener('click', this.toggleNavbarMobile);
			}
			else {
				bodyClick.remove();
			}
		},
		toggleNavbarMobile() {
			this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
			this.toggledClass = !this.toggledClass;
			this.bodyClick();
		},
		handleScroll() {
			const scrollValue =
        document.body.scrollTop || document.documentElement.scrollTop;
			const navbarColor = document.getElementById('toolbar');
			this.currentScrollValue = scrollValue;
			if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
				this.extraNavClasses = `md-${this.type}`;
				navbarColor.classList.remove('md-transparent');
			}
			else {
				if (this.extraNavClasses) {
					this.extraNavClasses = '';
					navbarColor.classList.add('md-transparent');
				}
			}
		},
		scrollListener() {
			resizeThrottler(this.handleScroll);
		},
	},
};
</script>
