<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter" parallax-active="true" :style="headerStyle" >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              About Us
            </h1>
            <h4>
              Meet the amazing team behind our work
            </h4>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-about-team section-team-1 pb-0">
        <div class="container">
          <div class="md-layout">
            <div
              class="md-layout-item md-size-66 md-large mx-auto text-center"
            >
				<h2 class="title">
					About the company (Sobre la compania)
				</h2>
				<h5 class="description">
					Family-owned and operated dedicated to delivering consistently excellent results for our clients. Our
					professional staff has 12+ years of hands-on experience. We are committed to providing quality
					gardening because we know the work you expect and deserve. We pride ourselves in going
					above and beyond for our customers who put their trust in us. Thanks to the recommendations of the
					people who support us, we are gradually being recognized.
				</h5>
            </div>
			<div
              class="md-layout-item md-size-66 md-large mx-auto text-center"
            >
				<h2 class="title">
					About Rogelio (Acerca de Rogelio)
				</h2>
				<h5 class="description">
					My personality is based on respect, seriousness, professionalism, and kindness never fails. The
					12 years of experience speaks for itself. If you trust me, my goal is to serve and exceed your
					expectations. As I grow I pledge to complete all my jobs in a timely manner and completing a
					final expectation with you before leaving the job.
				</h5>
            </div>
			<div
              class="md-layout-item md-size-66 md-large mx-auto text-center"
            >
				<h2 class="title">
					About the Team (Sobre el equipo)
				</h2>
				<h5 class="description">
					My team consists of very hard workers and they are very knowledgeable in what they do. They earn the affection of the people with whom we work,
					they respect the clients and work very seriously. With experience in residential yard clean ups, lawn care services and
					ongoing maintenance services. No job is too small or too big for our team. We hope that you
					trust us with your homes, as we pledge to not leave until costumer is satisfied with out work.
				</h5>
            </div>
          </div>
          <!-- <div class="md-layout custom-center">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <profile-card
                card-plain
                card-avatar
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="aboutTeam.aboutTeam1"
              >
                <template slot="cardContent">
                  <h4 class="card-title">
                    Roger
                  </h4>
                  <h6 class="card-category text-muted">
                    Founder/Lead Lanscaper
                  </h6>
                  <p class="card-description">
                    Coming Soon...
                  </p>
                </template>
              </profile-card>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <profile-card
                card-plain
                card-avatar
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="aboutTeam.aboutTeam2"
              >
                <template slot="cardContent">
                  <h4 class="card-title">
                    Roger's Brother
                  </h4>
                  <h6 class="card-category text-muted">
                    Landscaper
                  </h6>
                  <p class="card-description">
                    Coming Soon...
                  </p>
                </template>
              </profile-card>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <profile-card
                card-plain
                card-avatar
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="aboutTeam.aboutTeam2"
              >
                <template slot="cardContent">
                  <h4 class="card-title">
                    Third Person
                  </h4>
                  <h6 class="card-category text-muted">
                    Landscaper
                  </h6>
                  <p class="card-description">
                    Coming Soon...
                  </p>
                </template>
              </profile-card>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ProfileCard } from '@/components';
import Mixins from '@/plugins/basicMixins';

export default {
	components: {
		ProfileCard,
	},
	mixins: [Mixins.HeaderImage],
	bodyClass: 'about-us',
	data() {
		return {
			speciality: '',
			name: null,
			email: null,
			image: require('@/assets/img/landscapes/about.jpg'),
			aboutTeam: {
				aboutTeam1: require('@/assets/img/placeholder.jpg'),
				aboutTeam2: require('@/assets/img/placeholder.jpg'),
				aboutTeam3: require('@/assets/img/placeholder.jpg'),
				aboutTeam4: require('@/assets/img/placeholder.jpg'),
			},
		};
	},
};
</script>

<style lang="scss" scoped>
  .mt-2 {
    margin-top: 1.5rem;
  }
  .custom-center {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
</style>
