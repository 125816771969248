import Vue from 'vue';
import Vuex from 'vuex';

import App from './App.vue';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router';
import MaterialKit from './plugins/material-kit';
import 'es6-promise/auto';
import { Carousel, CarouselItem } from 'element-ui';

import store from './store';

Vue.config.productionTip = false;
Vue.use(MaterialKit);
Vue.use(Vuex);
Vue.use(Carousel);
Vue.use(CarouselItem);

new Vue({
	store,
	router,
	render: h => h(App),
}).$mount('#app');
