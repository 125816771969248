<template>
  <div
    id="FoodCentral"
    :class="{ 'nav-open': NavbarStore.showNavbar }"
  >
    <router-view name="header" />
    <router-view />
    <div id="notifications">
      <div v-if="alert.message" :class="`alert ${alert.type} bottom`">
        <div class="container">
          <button type="button" aria-hidden="true" class="close" @click="clearAlert()">
            <md-icon>clear</md-icon>
          </button>
          <div class="alert-icon">
            <md-icon>{{alert.icon}}</md-icon>
          </div>
          <b>{{alert.title}}</b> {{alert.message}}
        </div>
      </div>
    </div>
    <router-view name="footer" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
	name: 'app',
	computed: {
		...mapState({
			alert: state => state.alert,
		}),
	},
	methods: {
		...mapActions({
			clearAlert: 'alert/clear',
		}),
	},
	watch: {
		$route (){
      // clear alert on location change
			this.clearAlert();
		},
	},
};
</script>