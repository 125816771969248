import Vue from 'vue';
import Router from 'vue-router';

//  Main Pages
import Index from './views/Index.vue';
import About from './views/About.vue';
import Gallery from './views/Gallery.vue';
import Consult from './views/Consult.vue';

import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';

Vue.use(Router);

export default new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'home',
			components: { default: Index, header: MainNavbar, footer: MainFooter },
			props: {
				header: { colorOnScroll: 400, navbarTransparent: true },
			},
		},
		{
			path: '/about',
			name: 'about',
			components: { default: About, header: MainNavbar, footer: MainFooter },
			props: {
				header: { colorOnScroll: 400, navbarTransparent: true },
			},
		},
		{
			path: '/gallery',
			name: 'gallery',
			components: { default: Gallery, header: MainNavbar, footer: MainFooter },
			props: {
				header: { colorOnScroll: 400, navbarTransparent: true },
			},
		},
		{
			path: '/consult',
			name: 'consult',
			components: { default: Consult, header: MainNavbar, footer: MainFooter },
			props: {
				header: { colorOnScroll: 400, navbarTransparent: true },
			},
		},
	],
	scrollBehavior: to => {
		if (to.hash) {
			return { selector: to.hash };
		}
		return { x: 0, y: 0 };

	},
});
