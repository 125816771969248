// import dependency to handle HTTP request to our back end
// import axios from 'axios';
import Vuex from 'vuex';
import Vue from 'vue';
import { alert } from './alert.module';

//load Vuex
Vue.use(Vuex);

//to handle state
const state = {
	recipes: [],
	randRecipe: {},
};

//to handle state
const getters = {};

//to handle actions
const actions = {
// 	async getRandomRecipe({ commit }) {
// 		return axios.get('http://localhost:3001/api/recipes/rand')
// 			.then(response => {
// 				commit('SET_RAND_RECIPE', response.data);
// 			}).catch(err => {
// 				console.error(err);
// 			});
// 	},
};

//to handle mutations
const mutations = {
	SET_RAND_RECIPE(state, recipe) {
		state.randRecipe = recipe[0];
	},
};

//export store module
export default new Vuex.Store({
	state,
	getters,
	actions,
	mutations,
	modules: {
		alert,
	},
});